import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.dev';
import CryptoJS  from "crypto-js";


@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() { }

  do_api_encrypt = (_str) => {
    var key = CryptoJS.enc.Utf8.parse(environment.WEB_API_ENC_KEY);
    var iv = CryptoJS.enc.Utf8.parse(environment.WEB_API_ENC_IV);
    var enc_data = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(_str), key, { keySize: 128 / 8, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return enc_data.toString();
  }

  do_api_decrypt = (_str) => {
    var key = CryptoJS.enc.Utf8.parse(environment.WEB_API_ENC_KEY);
    var iv = CryptoJS.enc.Utf8.parse(environment.WEB_API_ENC_IV);
    var plain_text = CryptoJS.AES.decrypt(_str, key, { keySize: 128 / 8, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return plain_text.toString(CryptoJS.enc.Utf8); 
  }


}
