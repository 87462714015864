import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;

    // if (this.authService.getAuthFromLocalStorage() == null) {
    //   // window.location.reload();
    //   return false;
    // }

    const tokenExpiry = this.authService.getTokenExpiryFromLocalStorage();
    const remainingTimeInSeconds = Math.floor((Number(tokenExpiry) - new Date().getTime()));
    // const timeout = new Date((JSON.parse(atob(this.authService.getAuthFromLocalStorage().authToken.split('.')[1]))).exp * 1000);
    //     const currentDate = new Date();

    //     const remainingTimeInSeconds = Math.floor((timeout.getTime() - currentDate.getTime()));
        console.log(remainingTimeInSeconds);
        
    this.authService.expirationCounter(remainingTimeInSeconds);

    // this.authService.handleVisibilityChange();

    if (currentUser) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logoutLocal();
    return false;
  }
}
